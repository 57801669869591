//TODO ховеры, фокусы, эктивы
.link {
  color: var(--aliases-link);
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  word-break: break-word;
  hyphens: auto;

  cursor: pointer;
}
