.button {
  --button-font-weight: 500;
  --button-font-size: 16px;
  --button-min-width: 128px;
  --button-background-disabled: var(--palettes-gray-20);
  --button-cursor: pointer;
  --button-border: none;
  --button-outline: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: var(--button-min-width);
  height: var(--button-height);
  padding: var(--button-padding);
  border: var(--button-border);
  border-radius: var(--button-border-radius);

  color: var(--button-color);

  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);

  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  background-color: var(--button-background);

  outline: var(--button-outline);
  cursor: var(--button-cursor);

  &:hover,
  &:active {
    transition: 0.2s;
  }

  &:hover {
    --button-background: var(--button-background-hover);
    --button-color: var(--button-color-hover);
  }

  &:active {
    --button-background: var(--button-background-active);
  }

  &:disabled {
    --button-color: var(--palettes-gray-60);
    --button-background: var(--button-background-disabled);
    --button-cursor: not-allowed;
  }
}

.primary {
  --button-color: var(--palettes-white-100);
  --button-color-hover: var(--palettes-white-100);
  --button-background: var(--palettes-blue-100);
  --button-background-hover: var(--palettes-blue-80);
  --button-background-active: var(--palettes-blue-120);
}

.primary.linkDisabled,
.secondary.linkDisabled {
  --button-color: var(--palettes-gray-60);
  --button-background: var(--button-background-disabled);
  --button-cursor: not-allowed;
}

.primary.isDarkSurface {
  &:disabled {
    --button-background: var(--palettes-gray-160);
    --button-color: var(--palettes-gray-120);
  }
}

.primary.isDarkSurface.linkDisabled,
.secondary.isDarkSurface.linkDisabled {
  --button-background: var(--palettes-gray-160);
  --button-color: var(--palettes-gray-120);
  --button-cursor: not-allowed;

  &:hover {
    --button-background: var(--palettes-gray-160);
    --button-color: var(--palettes-gray-120);
  }
}

.primary.isDarkSurface.isLoading {
  --button-background: var(--palettes-gray-80);
}

.secondary {
  --button-color: var(--palettes-blue-100);
  --button-background: var(--palettes-blue-20);
  --button-color-hover: var(--palettes-blue-100);
  --button-background-hover: var(--palettes-blue-10);
  --button-background-active: var(--palettes-blue-40);

  &:active {
    --button-color: var(--palettes-blue-120);
  }
}

.secondary.isDarkSurface {
  --button-background: var(--palettes-blue-10);

  &:hover {
    --button-background:  var(--palettes-blue-20);
  }

  &:active {
    --button-color: var(--palettes-blue-120);
    --button-background: var(--palettes-blue-40);
  }

  &:disabled {
    --button-background: var(--palettes-gray-160);
    --button-color: var(--palettes-gray-120);
  }
}

.secondary.isDarkSurface.isLoading {
  --button-background: var(--palettes-gray-80);
}

.outline {
  --button-border: 2px solid var(--palettes-blue-100);
  --button-color: var(--palettes-blue-100);
  --button-background: none;
  --button-color-hover: var(--palettes-blue-80);
  --button-background-disabled: none;

  &:hover {
    --button-border: 2px solid var(--palettes-blue-80);
  }

  &:active {
    --button-border: 2px solid var(--palettes-blue-120);
    --button-color: var(--palettes-blue-120);
  }

  &:disabled {
    --button-border: 2px solid var(--palettes-gray-20);
  }
}

.outline.linkDisabled {
  --button-border: 2px solid var(--palettes-gray-20);
  --button-color: var(--palettes-gray-60);
  --button-cursor: not-allowed;
}

.outline.isLoading {
  --button-background: var(--palettes-gray-20);
  --button-border: none;
}

.outline.isDarkSurface {
  --button-border: 2px solid var(--palettes-blue-20);
  --button-color: var(--palettes-blue-20);

  &:hover {
    --button-border: 2px solid var(--palettes-blue-60);
    --button-color: var(--palettes-blue-60);
  }

  &:active {
    --button-border: 2px solid var(--palettes-blue-80);
    --button-color: var(--palettes-blue-80);
  }

  &:disabled {
    --button-border: 2px solid var(--palettes-gray-140);
    --button-color: var(--palettes-gray-140);
  }
}

.outline.isDarkSurface.linkDisabled {
  --button-border: 2px solid var(--palettes-gray-160);
  --button-color: var(--palettes-gray-120);
  --button-cursor: not-allowed;
}

.outline.isDarkSurface.isLoading {
  --button-background: var(--palettes-gray-80);

  &:disabled {
    --button-border: none;
  }
}

.tertiary {
  --button-color: var(--palettes-blue-100);
  --button-background: none;
  --button-color-hover: var(--palettes-blue-100);
  --button-background-hover: var(--palettes-blue-10);
  --button-background-active: var(--palettes-blue-20);
  --button-background-disabled: none;
}
.tertiary.isLoading {
  --button-background: var(--palettes-gray-20);
}

.tertiary.linkDisabled {
  --button-color: var(--palettes-gray-60);
  --button-background: none;
  --button-cursor: not-allowed;
}

.tertiary.isDarkSurface {
  --button-color: white;
  --button-background-hover: var(--palettes-blue-140);

  &:active {
    --button-background: var(--palettes-blue-160);
  }

  &:disabled {
    --button-color: var(--palettes-gray-140);
  }
}

.tertiary.isDarkSurface.linkDisabled {
  --button-color: var(--palettes-gray-140);
  --button-background: none;
  --button-cursor: not-allowed;
}

.tertiary.isDarkSurface.isLoading {
  --button-background: var(--palettes-gray-80);
}

.large {
  --button-height: 52px;
  --button-padding: 16px 14px;
  --button-border-radius: 16px;
}

.medium {
  --button-height: 44px;
  --button-padding: 10px 16px;
  --button-border-radius: 12px;
}

.small {
  --button-min-width: 80px;
  --button-height: 28px;
  --button-padding: 4px 12px;
  --button-font-size: 14px;
  --button-border-radius: 8px;
}

.grow {
  width: 100%;
}

.isLoading {
  --button-padding: 0;
  --button-min-width: 48px;
}

.icon {
  margin-right: 8px;
}

.iconOnly {
  --button-min-width: 48px;
}

.iconOnly .icon {
  margin-right: 0;
}
