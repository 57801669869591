.loader {
  --loader-size: 24px;
  --loader-track-color: var(--palettes-gray-40);
  --loader-spinner-color: var(--palettes-orange-100);

  width: var(--loader-size);
  height: var(--loader-size);

  animation: spin 1s linear infinite;

  fill: none;
}

.track {
  fill: var(--loader-track-color);
}

.progress {
  fill: var(--loader-spinner-color);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
