@import 'src/styles/variables';

.typography {
  --typography-h1-font-size: 28px;
  --typography-h1-line-height: 36px;
  --typography-h2-font-size: 24px;
  --typography-h2-line-height: 30px;
  --typography-h3-font-size: 20px;
  --typography-h3-line-height: 26px;
  --typography-h4-font-size: 18px;
  --typography-h4-line-height: 24px;
  --typography-subtitle-font-size: 16px;
  --typography-subtitle-line-height: 22px;
  --typography-button-m-font-size: 16px;
  --typography-button-m-line-height: 24px;
  --typography-button-s-font-size: 14px;
  --typography-button-s-line-height: 20px;
  --typography-body-l-font-size: 16px;
  --typography-body-l-line-height: 22px;
  --typography-body-m-font-size: 14px;
  --typography-body-m-line-height: 18px;
  --typography-caption-font-size: 12px;
  --typography-caption-line-height: 14px;

  margin: 0;

  color: var(--text-base);
  font-style: normal;

  @media (max-width: $xl) {
    --typography-h1-font-size: 26px;
    --typography-h1-line-height: 30px;
  }
}

.h1 {
  font-weight: bold;
  font-size: var(--typography-h1-font-size);
  line-height: var(--typography-h1-line-height);
}

.h2 {
  font-weight: bold;
  font-size: var(--typography-h2-font-size);
  line-height: var(--typography-h2-line-height);
}

.h3 {
  font-weight: bold;
  font-size: var(--typography-h3-font-size);
  line-height: var(--typography-h3-line-height);
}

.h4 {
  font-weight: bold;
  font-size: var(--typography-h4-font-size);
  line-height: var(--typography-h4-line-height);
  letter-spacing: 0.15px;
}

.subtitle {
  font-weight: 500;
  font-size: var(--typography-subtitle-font-size);
  line-height: var(--typography-subtitle-line-height);
  letter-spacing: 0.15px;
}

.button {
  font-weight: 500;

  &.m {
    font-size: var(--typography-button-m-font-size);
    line-height: var(--typography-button-m-line-height);
  }

  &.s {
    font-size: var(--typography-button-s-font-size);
    line-height: var(--typography-button-s-line-height);
  }
}

.body {
  font-weight: normal;

  &.m {
    font-size: var(--typography-body-m-font-size);
    line-height: var(--typography-body-m-line-height);
    letter-spacing: 0.25px;
  }
  &.bold {
    font-weight: 700;
  }
  &.normal {
    font-weight: 400;
  }
  &.l {
    font-size: var(--typography-body-l-font-size);
    line-height: var(--typography-body-l-line-height);
  }
}

.caption {
  font-weight: normal;
  font-size: var(--typography-caption-font-size);
  font-family: inherit;
  line-height: var(--typography-caption-line-height);
  letter-spacing: 0.4px;

  &.bold {
    font-weight: 700;
  }
}

.captionNormal {
  color: var(--text-tertiary);
  font-weight: 400;
}

.tableTitleB {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.tableTitleM {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.tableBody {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.tableCapture {
  color: var(--palettes-gray-140);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
}

.highlightTitle {
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  letter-spacing: 0.25px;

  @media (max-width: $lg) {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: initial;
  }

  @media (max-width: $md) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: initial;
  }
}

.highlightSubtitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;

  @media (max-width: $lg) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: initial;
  }

  @media (max-width: $md) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: initial;
  }
}
