.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: auto;
  height: 100vh;
  min-height: 100vh;

  text-align: center;
}

.content {
  padding: 40px;
}

.errorName {
  margin: 20px 0;

  color: black;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
}

.title {
  margin: 20px 0;

  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
}

.message {
  margin: 20px 0;

  font-size: 17px;
  line-height: 24px;
}

.button {
  text-transform: uppercase;
  text-decoration: none;
}
